/* ======= BOOTSTRAP (5) ======= */

$font-family-sans-serif: 'Open Sans', sans-serif; // Change Google font family in core/views/layouts/layout-nomesia.blade.php
$headings-font-family: 'Roboto', sans-serif;
$btn-font-family: 'Roboto', sans-serif;
$primary: #00A5DA;
$darkprimary: #0072BC;
$lightgray: #F4F4F4;
$white: #FFF;
$theme-colors: (
	"primary": $primary,
	"darkprimary": $darkprimary,
	"lightgray": $lightgray,
	"white": $white
	);
$spacer: 1rem;
$spacers: ();
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 3.5),
	7: ($spacer * 4),
	8: ($spacer * 5),
	9: ($spacer * 6),
	10: ($spacer * 7),
	11: ($spacer * 8),
	12: ($spacer * 9)
	), $spacers);
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.55;
$h4-font-size: $font-size-base * 1.35;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base * 1.1;
$btn-font-weight: 700;
$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$accordion-body-padding-y: 2rem;
$accordion-button-active-bg: #FFF;


@import '../../../node_modules/bootstrap/scss/bootstrap';