/* ====================  IMPORT  ==================== */

// BOOTSTRAP
@import 'bootstrap';

// FONTAWESOME (optional, remove if not used + remove in package.json)
@import 'fontawesome';

// CONTACTBOX FORM (remove if not used)
@import 'cb-form';

// OWL CAROUSEL
@import '../../../node_modules/owl.carousel/src/scss/owl.carousel';
@import '../../../node_modules/owl.carousel/src/scss/owl.theme.default';



/* ====================  CUSTOM  ==================== */


@include media-breakpoint-up(md) {
	html {
		font-size: 1.125rem;
	}
}

.bg-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.half-img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	padding: 0;
	min-height: 300px;
	@include media-breakpoint-up(md) {
		min-height: 450px;
	}
	> .position-absolute {
		width: 35%;
		top: 80%;
		transform: translateY(-80%);
		background-color: #FFF;
		padding: 2rem;
		&.pos-abs-left {
			left: 0;
		}
		&.pos-abs-right {
			right: 0;
		}
	}
}

h1,
.h1 {
	font-weight: 900;
}

h2,
.h2 {
	font-weight: 400;
}

h3,
.h3 {
	font-weight: 700;
	text-transform: uppercase;
}

h6,
.h6 {
	color: $primary;
	text-transform: uppercase;
	font-weight: 400;
}

.divider {
	height: 6px;
	width: 100px;
	margin-top: 1.25rem;
	margin-bottom: 1.5rem;
	background: #2E353E;
	background: linear-gradient(to right, #2E353E 0%, #2E353E 25%, transparent 25%, transparent 33%, $primary 33%, $primary 100%);
	&-light {
		background: $primary;
		background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 25%, transparent 25%, transparent 33%, $primary 33%, $primary 100%);
	}
}

.big-border-bottom {
	&-primary {
		border-bottom: 14px solid $primary;
	}
	&-white {
		border-bottom: 14px solid $white;
	}
}

.btn {
	text-transform: uppercase;
	&-primary,
	&-outline-primary {
		&:hover,
		&:active {
			color: #FFF;
		}
	}
	&-outline-primary,
	&-outline-white {
		border-width: 3px;
	}
	&-primary {
		color: #FFF;
		&:focus {
			color: #FFF;
		}
	}
	&-outline-white {
		&:focus,
		&:hover,
		&:active {
			color: $primary;
		}
	}
	&-white {
		color: $primary;
		&:focus,
		&:hover,
		&:active {
			color: $primary;
			background-color: shade-color(#fff, 5%);
		}
	}
}

.card-title {
	a {
		text-decoration: none;
	}
}

.card-text {
	font-size: .9rem;
}

.accordion-body {
	font-weight: 300;
}

.accordion-button {
	font-size: 1.2rem;
	font-weight: 500;
}

.accordion-icon {
	.accordion-button {
		text-transform: uppercase;
		color: $darkprimary;
		span {
			width: 70%;
			&.fa-stack {
				width: 20%;
				@include media-breakpoint-up(lg) {
					width: 12%;
				}
			}
		}
	}
}

.media-object {
	display: flex;
	align-items: center;
	&-icon {
		flex-shrink: 0;
		.fa-stack {
			font-size: 2rem;
			@include media-breakpoint-up(md) {
				font-size: 2.5rem;
			}
		}
	}
	&-text {
		flex-grow: 1;
		margin-left: 1rem;
	}
}

a.media-object {
	padding: 1rem;
	text-decoration: none;
	transition: $transition-base;
	&:hover,
	&:active,
	&:focus {
		background: rgba(255,255,255,.3);
		box-shadow: $box-shadow-lg;
	}
	&-icon {
		.fa-stack {
			font-size: 1.5rem;
			@include media-breakpoint-up(sm) {
				font-size: 1.7rem;
			}
			@include media-breakpoint-up(xl) {
				font-size: 2.5rem;
			}
		}
	}
}

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	border-radius: 3px;
	background: $primary;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
	display: none;
	#top-arrow {
		transform: rotate(45deg);
		border-top: 5px solid #fff;
		border-left: 5px solid #fff;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}

.owl-theme {
	.owl-nav {
		[class*=owl-] {
			margin: 0;
			&:hover {
				background: transparent;
			}
		}
		.owl-prev,
		.owl-next {
			width: 14px;
			height: 27px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			display: block !important;
			border: 0 none;
			&:hover {
				i {
					color: $darkprimary;
				}
			}
			i {
				color: $primary;
				transition: $transition-base;
			}
		}
		.owl-prev {
			left: -14px;
		}
		.owl-next {
			right: -14px;
		}
	}
}